import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Tawabi'`}</strong>{` or `}<strong parentName="p" {...{
        "className": "firstword"
      }}>{`التوابع`}</strong>{` is a plural of word `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`تابع`}</code>{`.  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`تابع`}</code>{` comes from  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`تبع  يتبع`}</code><br parentName="p"></br>{`
`}{`meaning `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`to follow`}</code></p>
    <br />
    <p>{`Tawabi' follows what is before it in I'rab and has no I'rab of its own`}</p>
    <blockquote>
      <p parentName="blockquote">{`التوابع تتبع ما قبلها في الإعراب وليس لها إعراب خاص`}</p>
    </blockquote>
    <br />
    <p>{`For example, in رأيت `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`الطالبَ`}</strong>{` `}<strong parentName="p" {...{
        "className": "bg-cyan"
      }}>{`المجتهدَ`}</strong>{`, we have `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`المجتهدَ`}</code>{` is following `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`الطالبَ`}</code>{` in I'rab (as Sifa)`}</p>
    <br />
    <p>{`There are four types of Tawabi'`}</p>
    <ul>
      <li parentName="ul">{`النعت`}</li>
      <li parentName="ul">{`العطف`}
        <ul parentName="li">
          <li parentName="ul">{`عطف نسق`}</li>
          <li parentName="ul">{`عطف بيان`}</li>
        </ul>
      </li>
      <li parentName="ul">{`التوكيد`}</li>
      <li parentName="ul">{`البدل`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "351px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.75460122699387%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBElEQVQ4y51Ua2/TQBD0/xdf+QIVEpCCVKKKJBQEhEIlGhBSK1GpMaEJDVIcp4/YiZ/new17dt0kViKglk53Nzc73p1b28KaR2uN9Y/+K8faJMa4wjTkmAYZPJr9SGAWy3ydCbVRdKPgaJrh1BEYXAO9iYLtcHw/D3E64nB9/v+CY1/gZBjh4XYTtd027t1/jCf19+i5EmMvu4Ogx3HyO8W7jo2D4yHeHtqUYYAuZe36/yhoCCXJ+DQnz2KKvbxKcU0WBIkiHwX4kodVUat6sExQUoKn2c1gEIxDE1atZjneWgeaOUs5BCfBTEBSRkLIfG+GOdsUZ5VACZrgjDJSUhkGWLLwq1hrKCFzjpSrpeeCy/ULEuOM3+6NV+ImqDwv9+b1hiuEWml5S6Yp5HyGaHSBzJ8V3nESDQPEziV0GEIrlQ85nyN1qTENJkTxAXh+zjN8xRgs1j/DZL+NXqsJ72unII0dDN/sodt4icHrV9BJAhkEGH9o4/jFDn4RxiYuvVnB/3IIu9nAWauBZNCHlf7soVvfwdHzbXidz4WgM8JR7Sn2tx6gT0RFgooEz/da+PRoCz9264UgeXZ18BHfntVgk0ZCyVnSkKkUJDGkKaUsOY4IS4Aoyss1LYQopAZlOaZEcdOKYpAWPMXSxaXoRXPd7vXK/6VYqwqGCm+lbaq9dZfxBwNJ1Q+KBu/YAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Four Types of Tawabi",
          "title": "Four Types of Tawabi",
          "src": "/static/3d549b7bb684b8a5c03ec6d8b5216db6/7c2a6/4-types-of-tawabi.png",
          "srcSet": ["/static/3d549b7bb684b8a5c03ec6d8b5216db6/222b7/4-types-of-tawabi.png 163w", "/static/3d549b7bb684b8a5c03ec6d8b5216db6/ff46a/4-types-of-tawabi.png 325w", "/static/3d549b7bb684b8a5c03ec6d8b5216db6/7c2a6/4-types-of-tawabi.png 351w"],
          "sizes": "(max-width: 351px) 100vw, 351px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <h2><strong parentName="h2" {...{
        "className": "arabic"
      }}>{`النعت`}</strong></h2>
    <p>{`النعت comes from the word  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`نعت  ينعت  انعت  النعت`}</code></p>
    <p>{`meaning characteristic OR features. It is synonymous to word `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`صفة`}</code></p>
    <br />
    <p>{`Types of النعت`}</p>
    <ul>
      <li parentName="ul">{`النعت الحقيقي`}</li>
      <li parentName="ul">{`النعت السببي`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "401px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.21472392638037%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABW0lEQVQoz42Qy0/CQBDG+/9fjUeJggcCiagh4aDyEOyTbUXLQ40HH6UQCUS6bbc7u1sXE4ggRiZ7mJ3v++3MjpKuB+ei78W9d/o05s+TtO/R+1cymoGURLoZysYdmBj40H2LShdWJl+pNN3HcepNYRv7C2ZcPAxpzyN6d6S5vvuC+0Pwvzv/D8uIEx4ShiMeYBZgimOQ4+wKL34u7ZwvEsYZsFTsCgugIJkfK+Q0WVbE37CUALjgW/rI0aW02VkwxmjCKAWS4OmcJYkAkMW1RxnjCwMJpIEkMpdH2hRsmdBxuNshDsKmCXe3EbJCB3FCVmRoI+mhHYcgFCJLJrHdDlFbiXS1d36q5rJOsTAon9UzB3YhnzgIcLDaQWjodjFf3t9r5Y7c0ol6nG0eHYaWoWBd8+tVr3Y1VVuBoX00G5+6GtuIRdGyMwSm7lUvR42aVOeGNmldz7SbGFlfwmtsEp0nAM0AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Types of Nait",
          "title": "Types of Nait",
          "src": "/static/190e00fa1451ed9815f3b23420f37506/9144d/nait.png",
          "srcSet": ["/static/190e00fa1451ed9815f3b23420f37506/222b7/nait.png 163w", "/static/190e00fa1451ed9815f3b23420f37506/ff46a/nait.png 325w", "/static/190e00fa1451ed9815f3b23420f37506/9144d/nait.png 401w"],
          "sizes": "(max-width: 401px) 100vw, 401px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <h3><strong parentName="h3" {...{
        "className": "arabic"
      }}>{`النعت الحقيقي`}</strong></h3>
    <p>{`In this النعت, we describe noun before it i.e. `}</p>
    <blockquote>
      <p parentName="blockquote">{`ينعت اسماً سابقاً عليه`}</p>
    </blockquote>
    <br />
    <p>{`For example`}</p>
    <ul>
      <li parentName="ul">{`In جاء الطالبُ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`المجتهدُ`}</strong>{`, here `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`المجتهدُ`}</code>{` describe `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`الطالبُ`}</code>{` before it (Raf Construction)`}</li>
      <li parentName="ul">{`In رأيت الطالبَ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`المجتهدَ`}</strong>{`, here `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`المجتهدَ`}</code>{` describe `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`الطالبَ`}</code>{` before it (Nasb Construction)`}</li>
      <li parentName="ul">{`In ذهبت إلى الطالبِ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`المجتهدِ`}</strong>{`, here `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`المجتهدِ`}</code>{` describe `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`الطالبِ`}</code>{` before it (Jar Construction)`}</li>
      <li parentName="ul">{`In جاء الطلابُ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`المجتهدُون`}</strong>{`, here `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`المجتهدُون`}</code>{` describe `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`الطلابُ`}</code>{` before it (Plural Construction)`}</li>
      <li parentName="ul">{`Quranic Example:`}
        <ul parentName="li">
          <li parentName="ul">{`فَفَتَحْنَا أَبْوَابَ السَّمَاءِ بِمَاءٍ `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`مُّنْهَمِرٍ`}</strong></li>
          <li parentName="ul">{`إِنَّا أَرْسَلْنَا عَلَيْهِمْ رِيحًا `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`صَرْصَرًا`}</strong>{` فِي يَوْمِ نَحْسٍ `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`مُّسْتَمِرٍّ`}</strong></li>
          <li parentName="ul">{`وَمَنْ أَظْلَمُ مِمَّنِ افْتَرَىٰ عَلَى اللَّهِ الْكَذِبَ وَهُوَ يُدْعَىٰ إِلَى الْإِسْلَامِ ۚ وَاللَّهُ لَا يَهْدِي الْقَوْمَ `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`الظَّالِمِينَ`}</strong></li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`النعت follows المنعوت in following things`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`I'rab (الإعراب)`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Example: جاء الطالبُ المجتهدُ, both الطالبُ and المجتهدُ are Raf`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`In Gender (النوع i.e. في التذكير والتأنيث)`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Example: جاءت الطالبة المجتهدة, both الطالبة and المجتهدة are Feminine`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`In Count (العدد i.e. الإفراد – التثنية – الجمع)`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Example: جاء الطالب `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`المجتهد`}</strong>{` (Singular), جاء الطالبان `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`المجتهدان`}</strong>{` (Dual), جاء الطلاب `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`المجتهدون`}</strong>{` (Plural)`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`In Definite/Indefinite(التعريف والتنكير)`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Example: جاء الطالب `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`المجتهد`}</strong>{` (Definite), جاء طالب `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`مجتهد`}</strong>{` (Indefinite)`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "exception"
      }}>{`Exception Rule`}</strong><br parentName="p"></br>{`
`}{`If المنعوت is Plural (جمع) + Feminine (مؤنث) + NOT Rational (غير عاقل), We can use Singular or Plural النعت.`}<br parentName="p"></br>{`
`}{`For Example,`}</p>
    <ul>
      <li parentName="ul">{`هذه بيوت `}<strong parentName="li">{`عالية`}</strong>{` (Singular النعت) `}</li>
      <li parentName="ul">{`هذه بيوت `}<strong parentName="li">{`عاليات`}</strong>{` (Plural النعت) `}</li>
      <li parentName="ul">{`هذه بيوت `}<strong parentName="li">{`عوال`}</strong>{` (Plural Takseer النعت)`}</li>
    </ul>
    <br />
    <h3><strong parentName="h3" {...{
        "className": "arabic"
      }}>{`النعت السببي`}</strong></h3>
    <p>{`In this النعت, we describe noun after it i.e. `}</p>
    <blockquote>
      <p parentName="blockquote">{`ينعت اسماً يأتي بعده`}</p>
    </blockquote>
    <br />
    <p>{`For example`}<br parentName="p"></br>{`
`}{`In جاء طالبٌ مجتهدٌ أخوه (Student came whose brother is hardworking)`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ه`}</code>{` which returns to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`طالبٌ`}</code>{` is a must have`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`طالبٌ`}</code>{` is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`منعوت`}</code>{` in grammar even though `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مجتهدٌ`}</code>{` is describing `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أخ`}</code>{` in meaning`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`أخو`}</code>{` is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`فاعل`}</code>{` as `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مجتهدٌ`}</code>{` in this sentence acts as Ism fa'il i.e. in meaning of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`يجتهد`}</code></li>
    </ul>
    <br />
    <p>{`Irab of  جاء طالبٌ مجتهدٌ أخوه will be,`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`طالب`}</strong>{`
(فاعل (منعوت`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`مجتهد`}</strong>{`
نعت سببي مرفوع`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`أخو`}</strong>{`
فاعل مرفوع بالواو`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p">{`Quranic Example`}</strong>{`  `}</p>
    <blockquote>
      <p parentName="blockquote">{`ربنا أخرجنا من هذه القريةِ الظالمِ أهلُها`}</p>
    </blockquote>
    <br />
    <p>{`Irab of ربنا أخرجنا من هذه القريةِ الظالمِ أهلُها will be,`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`القرية`}</strong>{`
بدل مجرور`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`الظالم`}</strong>{`
نعت سببي مجرور`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`أهل`}</strong>{`
فاعل مرفوع`}</p>
    </blockquote>
    <br />
    <p>{`النعت السببي follows المنعوت (i.e. the noun before it) in following things`}</p>
    <ul>
      <li parentName="ul">{`I'rab (الإعراب)`}
        <ul parentName="li">
          <li parentName="ul">{`Example: رأيت `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`طالباً`}</strong>{` `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`مجتهداً`}</strong>{` أخوه, here `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`مجتهداً`}</code>{` follows `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`طالباً`}</code></li>
        </ul>
      </li>
      <li parentName="ul">{`In Definite/Indefinite(التعريف والتنكير)`}
        <ul parentName="li">
          <li parentName="ul">{`Example: رأيت `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`طالباً`}</strong>{` `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`مجتهداً`}</strong>{` أخوه, here `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`مجتهداً`}</code>{` follows `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`طالباً`}</code></li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`النعت السببي follows الاسم اللاحق (i.e. the noun after it) in following things`}</p>
    <ul>
      <li parentName="ul">{`In Gender (النوع i.e. في التذكير والتأنيث)`}
        <ul parentName="li">
          <li parentName="ul">{`Example: رأيت `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`طالباً`}</strong>{` `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`مجتهدةً`}</strong>{` أختُه, here `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`مجتهدةً`}</code>{` follows `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`أختُ`}</code></li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`We can also divide `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`النعت`}</code>{` as`}</p>
    <ul>
      <li parentName="ul">{`نعت مفرد i.e. it is not Jumla or Shibu Jumla (ليس جملة ولا شبه جملة)`}
        <ul parentName="li">
          <li parentName="ul">{`Example: رأيت طالباً `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`مجتهداً`}</strong></li>
        </ul>
      </li>
      <li parentName="ul">{`النعت الجملة`}
        <ul parentName="li">
          <li parentName="ul">{`Example: رأيت طالباً `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`يقرأ الكتاب`}</strong>{` here `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`يقرأ الكتاب`}</code>{` is `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`الجملة الفعلية في محل نصب نعت`}</code></li>
          <li parentName="ul">{`Example: سمعت طالباً `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`قراءتُه جميلة`}</strong>{` here `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`قراءتُه جميلة`}</code>{` is `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`الجملة الاسمية في محل نصب نعت`}</code></li>
          <li parentName="ul">{`Example: رأيت طالباً `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`في المسجد`}</strong>{` here `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`في المسجد`}</code>{` is `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`وشبه الجملة في محل نصب نعت`}</code>{` OR `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`وشبه الجملة متعلق بنعت محذوف تقديره كائناً`}</code></li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p">{`Golden Rule`}</strong></p>
    <ul>
      <li parentName="ul">{`الجمل وأشباه الجمل بعد النكرات صفات`}</li>
      <li parentName="ul">{`الجمل (وأشباه الجمل) بعد النكرات صفات وبعد المعارف أحوال`}</li>
    </ul>
    <br />
    <h2><strong parentName="h2" {...{
        "className": "arabic"
      }}>{`العطف`}</strong></h2>
    <p>{`العطف is divided into two categories`}</p>
    <ul>
      <li parentName="ul">{`عطف بيان Or البدل`}</li>
      <li parentName="ul">{`عطف نسق`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "401px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.21472392638037%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABU0lEQVQoz42Q2U7CUBBA+f8/kER9wkZDMCESHlBkD6UtLbggMRgjskhkEWjvMnNvHaIYQY1M5mG2M1sk3BSldOuZt/pw/6IfXsP2AK+e+HAGlNLhtkS2fEBFQPNxsW+korFkPF2562N/Cr+xP2BUut2XN11m3g7LzZ7XmdIWgxUc/g+TMIE+Rz/ApU8KSwaAeld4dTmVqxWgUCFgqHeFNUgg5tsLlRTriP4bphSA+pi5/UhASm1PVogohQKJQi7GcxRCrdyND2lEDQCMLyZz4AKlJJc04ts12XC56/i2xd062dDweMNFzr5I33UCuwZNjzm28OpkBI4V1O3INH9ZNWLNRLyTTjknxvVpIhPdGxXzKgg+b5aSWaZvmfax0T5Lmkex3OEBcyxB8KRUGOSys2plXC72sufTSmmUzy0sU7H1ZNrXsQjuXmTeqKxUoNa8blPwHfRXbMV4V9wpAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Types of Atf",
          "title": "Types of Atf",
          "src": "/static/205cf29a15915d85ba61715d031804d2/9144d/atf.png",
          "srcSet": ["/static/205cf29a15915d85ba61715d031804d2/222b7/atf.png 163w", "/static/205cf29a15915d85ba61715d031804d2/ff46a/atf.png 325w", "/static/205cf29a15915d85ba61715d031804d2/9144d/atf.png 401w"],
          "sizes": "(max-width: 401px) 100vw, 401px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Let's start with `}</p>
    <h3><strong parentName="h3" {...{
        "className": "arabic"
      }}>{`عطف نسق`}</strong></h3>
    <p>{`In عطف نسق, we use حروف العطف which are 10 in number. First being`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`الواو`}</strong><br parentName="p"></br>{`
`}{`The word coming after و share the same Hukm or meaning.`}<br parentName="p"></br>{`
`}{`For example in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`جاء زيدٌ وعمروٌ`}</code>{`, `}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`و`}</code>{` is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`حروف العطف`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`عمروٌ`}</code>{` is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`معطوف مرفوع`}</code>{` and in the meaning of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`فاعل`}</code>{`. `}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`زيدٌ`}</code>{` and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`عمروٌ`}</code>{` share the same meaning i.e. `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`جاء`}</code></li>
      <li parentName="ul">{`We can't say who came first it can be Zaid or Amr or both can come together. That's why `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`الواو`}</code>{` is known as `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`تفيد مُطْلق المشاركة`}</code></li>
      <li parentName="ul">{`Quranic Example: `}
        <ul parentName="li">
          <li parentName="ul">{`يَا أَيُّهَا الَّذِينَ آمَنُوا قُوا أَنفُسَكُمْ وَ`}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أَهْلِيكُمْ`}</strong>{` نَارًا وَقُودُهَا النَّاسُ وَ`}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`الْحِجَارَةُ`}</strong></li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`الفاء`}</strong><br parentName="p"></br>{`
`}{`This harf provides `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`الترتيب`}</code>{` i.e. order and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`التعقيب`}</code>{` i.e. the meaning of right after him.`}<br parentName="p"></br>{`
`}{`For example in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`جاء زيدٌ فعمروٌ`}</code>{`,`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ف`}</code>{` is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`حروف العطف`}</code></li>
      <li parentName="ul">{`Zaid came first`}</li>
      <li parentName="ul">{`Amr came immediately after zaid`}</li>
      <li parentName="ul">{`Quranic Exapmle: `}
        <ul parentName="li">
          <li parentName="ul">{`فتلقى آدم من ربه كلمات `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`ف`}</strong>{`تاب عليه`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`ثم`}</strong><br parentName="p"></br>{`
`}{`This harf provides `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`الترتيب`}</code>{` i.e. order  and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`التراخي`}</code>{` i.e. the meaning of after a while.`}<br parentName="p"></br>{`
`}{`For example in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`جاء زيدٌ ثم عمروٌ`}</code>{`,`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ثم`}</code>{` is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`حروف العطف`}</code></li>
      <li parentName="ul">{`Zaid came first`}</li>
      <li parentName="ul">{`Amr came a while after zaid`}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`حَتَّى`}</strong><br parentName="p"></br>{`
`}{`This harf is mostly used as Harf Jar and sometimes as Harf Atf.  `}</p>
    <p>{`We use حَتَّى for  `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading2"
        }}>{`بيان الشرف`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`For example: يموت الناسُ حتى الأنبياءُ meaning People will die even the prophets (will die)`}</li>
          <li parentName="ul">{`In above statement we start from common people to prophets i.e. from general to high. After حَتَّى we mentioned someone honorable.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading2"
        }}>{`بيان الخِسَّة`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`For example: يرزق اللهُ الناسَ حتى الكفارَ meaning Allah will provide the rizk to the people even to the Kafir`}</li>
          <li parentName="ul">{`In above statement we start from common people to disbelievers i.e. from general to low.After حَتَّى we mentioned someone very bad.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading2"
        }}>{`بيان العموم`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`For example: أكلتُ السمكةَ حتى رأسَها meaning I ate the fish till its head (i.e. I ate all the fish)`}</li>
          <li parentName="ul">{`In above statement we have same category after حَتَّى.`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`Lets ponder over حَتَّى more by taking following examples,`}</p>
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 1:`}</strong>{` `}<strong parentName="p">{`حَتَّى As Harf Atf`}</strong><br parentName="p"></br>{`
`}{`حَتَّى can come as حرف عطف.`}<br parentName="p"></br>{`
`}{`For e.g. `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`أكلتُ السمكةَ حتى `}<strong parentName="p" {...{
            "className": "bg-yellow"
          }}>{`رأسَها`}</strong>{` (I ate the fish with its head)`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p" {...{
                "className": "arabic irab"
              }}>{`حتى`}</strong>{`
حرف عطف`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p" {...{
                "className": "arabic irab"
              }}>{`رأس`}</strong>{`
معطوف منصوب `}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`جاء الحجاجُ حتى الضعفاءُ`}</p>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 2:`}</strong>{` `}<strong parentName="p">{`حَتَّى As Harf استئناف`}</strong><br parentName="p"></br>{`
`}{`حَتَّى can come as حرف استئناف meaning we use it at the beginning of the sentence.`}<br parentName="p"></br>{`
`}{`For e.g.أكلتُ السمكةَ حتى `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`رأسُها`}</strong>{` (I ate the fish with its head)`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` خبر of رأسُها is estimated as أكلته`}</p>
    <br />
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p" {...{
            "className": "arabic irab"
          }}>{`حتى`}</strong>{`
حرف ابتداء`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p" {...{
            "className": "arabic irab"
          }}>{`رأس`}</strong>{`
مبتدأ مرفوع `}</p>
      </li>
    </ul>
    <br />
    <p>{`In this case either الجملة الاسمية or الفعل الماضي can come after Hatta.  `}</p>
    <ul>
      <li parentName="ul">{`الجملة الاسمية example: جاء الطلاب `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`حتى`}</strong>{` علي جاء`}</li>
      <li parentName="ul">{`الفعل الماضي example: لم أذهب `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`حتى`}</strong>{` قرأت الكتاب`}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 3:`}</strong>{` `}<strong parentName="p">{`حَتَّى As Harf Jar`}</strong><br parentName="p"></br>{`
`}{`حَتَّى can come as حرف جر.`}<br parentName="p"></br>{`
`}{`For e.g. أكلتُ السمكةَ حتى `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`رأسِها`}</strong>{` (I ate the fish till its head (i.e. He hasn't eaten the head))`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p" {...{
            "className": "arabic irab"
          }}>{`حتى`}</strong>{`
حرف جر`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p" {...{
            "className": "arabic irab"
          }}>{`رأس`}</strong>{`
اسم مجرور `}</p>
      </li>
    </ul>
    <br />
    <p>{`In this case either اسم or الفعل المضارع can come after Hatta.  `}</p>
    <ul>
      <li parentName="ul">{`Quranic Example: `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`حتى`}</strong>{`  مطلعِ الفجر (here مطلعِ is اسم)`}</li>
      <li parentName="ul">{`(لن أذهب `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`حتى`}</strong>{` أقرأَ الكتاب  (حتى قراءةِ الكتاب`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61.34969325153374%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACDElEQVQoz3WTyW4TQRCG8wxwQeLGIUICDkg8QiTEEyBF4sgFpAgknoAX4MIxSNxy4MSVTbYEWZTgxEnsxBNn8TK2x2OPZ5/xbB89YweMl5K61f236q/6q6qXGFuSJNkanSOxR/hDD9+2wfOIw5fo2iqy3CQIQizLZDAY0Gw2iaLoL8cSU3ZNmlr1Qkb6/gVaTY4O77Czc5ODg322NrcpHR4L7Ih8Pk8cx7OEkxkag3do6gqe+4lQBO9rGnr9I2F/XWQXMIwCtKGOE3m4rjuPMJkifCtIHgnnjeyuayqfnYQNPca27AzTAgMn9jAMY7HkSbkMQb6U2S5KtE1YbtS4Udxnd2+X03KZykmFymmFXC5HGIbzMxyBLeLoB5g/0ZQ25w0Fyw15Xr3iWauLrhs06nV6vR6qqtJutyd8x4QjuUEGDrT31C+XhOQnuJ4vaqmiCqeOqYikh/8UaD0RVMc0zdkMRxFSUjEsSZ8wLol7I6uP3O9ghA6r1lMeX60gSRJdpSvGR0ZRFMpC/hzJU/UbW61WY+tkj06kca9wl9uFW1SkCqXjEucXVarVMwqFwmyXo2TUpW/OVx62H3C/t8za4EWGpV21DYtN8xcFfmcddWyHwI3FsIPve7OEcTICin6RN/3XvNLW+GCviwey35BKT+y0HmRzl9bNMYf4biDOxuLBnvk1/I9FRCyya/8/66aE65AxvocAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hatta forms",
          "title": "Hatta forms",
          "src": "/static/3f240e20ff06863d366ec6628eb9938e/0a47e/hatta.png",
          "srcSet": ["/static/3f240e20ff06863d366ec6628eb9938e/222b7/hatta.png 163w", "/static/3f240e20ff06863d366ec6628eb9938e/ff46a/hatta.png 325w", "/static/3f240e20ff06863d366ec6628eb9938e/0a47e/hatta.png 600w"],
          "sizes": "(max-width: 600px) 100vw, 600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`أَمْ`}</strong><br parentName="p"></br>{`
`}{`أَمْ is divided into two types`}</p>
    <ul>
      <li parentName="ul">{`حرف عطف للتسوية`}
        <ul parentName="li">
          <li parentName="ul">{`In this `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`أَمْ`}</code>{` comes with `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`سواء`}</code>{` and `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`أ`}</code>{` (known as `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`حرف تسوية`}</code>{`)`}</li>
          <li parentName="ul">{`Example: `}<strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`سواء`}</strong>{` عليهم `}<strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`أ`}</strong>{`أنذرتهم `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أم`}</strong>{` لم تنذرهم. In this sentence warning them is equal to not warning them `}</li>
          <li parentName="ul">{`Quranic Example: سواء علينا `}<strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`أ`}</strong>{`جزعنا `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أم`}</strong>{` صبرنا`}</li>
        </ul>
      </li>
      <li parentName="ul">{`حرف عطف للتعيين`}
        <ul parentName="li">
          <li parentName="ul">{`In this `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`أَمْ`}</code>{` comes with `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`أ`}</code>{` known as `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`حرف استفهام`}</code></li>
          <li parentName="ul">{`تعيين means to choose one among them`}</li>
          <li parentName="ul">{`Example: `}<strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`أ`}</strong>{`جاء زيد `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أم`}</strong>{` عمرو؟. In this sentence I'm sure one among Zaid and Amr came but don't know who came exactly`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`أو`}</strong><br parentName="p"></br>{`
`}{`أو is divided into two types  `}</p>
    <ul>
      <li parentName="ul">{`حرف عطف للإباحة`}
        <ul parentName="li">
          <li parentName="ul">{`Example: ادرس النحو `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أو`}</strong>{` الصرفَ. This sentence implies we studied either Sarf Or either Nahw Or we studied both`}</li>
        </ul>
      </li>
      <li parentName="ul">{`حرف عطف للتخيير`}
        <ul parentName="li">
          <li parentName="ul">{`Example: تزوج هنداً `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أو`}</strong>{` أختَها. This sentence implies you can marry either Hind or her sister but not both`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`Quranic Example:`}</p>
    <ul>
      <li parentName="ul">{`لبثنا يوما `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`أو`}</strong>{` بعض يوم`}</li>
      <li parentName="ul">{`فكفارته إطعام عشرة مساكين من أوسط ما تطعمون أهليكم `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`أو`}</strong>{` كسوتهم `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`أو`}</strong>{` تحرير رقبة`}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`لكن`}</strong><br parentName="p"></br>{`
`}{`This harf is used for corrections i.e. للاستدراك .  `}</p>
    <br />
    <p>{`It becomes harf Atf under two conditions`}</p>
    <ul>
      <li parentName="ul">{`و doesn't come before it (لا تأتي قبلها واو)`}</li>
      <li parentName="ul">{`There is a negation before it (أن يكون قبلها نفي أو نهي)`}</li>
    </ul>
    <p>{`Example: `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`لم`}</strong>{` أقرأ الدرس `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`لكن`}</strong>{` حفظت القرآن`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`لا`}</strong><br parentName="p"></br>{`
`}{`This harf is used for negating the Hukm of المعطوف i.e. تفيد نفي الحكم عن المعطوف. `}</p>
    <br />
    <p>{`It becomes harf Atf under two conditions`}</p>
    <ul>
      <li parentName="ul">{`Negation doesn't come before it (ألا يكون قبلها نفي)`}</li>
      <li parentName="ul">{`It doesn't come with harf Atf (ألا تأتي مع حرف عطف)`}</li>
    </ul>
    <p>{`Example: جاء زيدٌ `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`لا`}</strong>{` عمروٌ meaning Zaid came and Amr didn't come`}</p>
    <br />
    <p>{`If we use negation and a harf Atf (e.g. و) then لا will not be harf Atf.`}<br parentName="p"></br>{`
`}{`For example, in `}<strong parentName="p">{`ما`}</strong>{` جاء زيد `}<strong parentName="p">{`ولا`}</strong>{` عمرو, here الواو is  حرف عطف and لا is حرف زائد لتوكيد النفي`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`بل`}</strong><br parentName="p"></br>{`
`}{`It is divided into two`}</p>
    <ul>
      <li parentName="ul">{`حرف عطف للإضراب `}
        <ul parentName="li">
          <li parentName="ul">{`When بل is used for correcting the information presented in first part of the sentence.  `}</li>
          <li parentName="ul"><strong parentName="li">{`Rule`}</strong>{` The first part of sentence must not have any negation. (الكلام قبها موجب)`}</li>
          <li parentName="ul">{`Example: سوريا في إفريقيا `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`بل`}</strong>{` آسيا meaning Syria is in Africa rather its in Asia. In this sentence we corrected that Syria is not in Africa but its in Asia`}</li>
        </ul>
      </li>
      <li parentName="ul">{`الإقرار ثم المخالفة`}
        <ul parentName="li">
          <li parentName="ul">{`When بل is used to give opposite meaning of what is mentioned in first part of sentence.`}</li>
          <li parentName="ul"><strong parentName="li">{`Rule`}</strong>{` The first part of sentence must have negation.`}</li>
          <li parentName="ul">{`Example: `}<strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`لم`}</strong>{` يحضر زيد `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`بل`}</strong>{` عمرو meaning Zaid did not attend rather Umar (i.e. Umar attended). So the first part of sentence is correct and second part after بل is opposite in meaning w.r.t. first part`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`إمَّا`}</strong><br parentName="p"></br>{`
`}{`There is a debate among the Ulema whether this Harf is Atf or not.`}<br parentName="p"></br>{`
`}{`Imam Hariri considers إمَّا as harf Atf.  `}</p>
    <br />
    <p>{`It has similar meaning of او (in إباحة and تخيير ). `}</p>
    <br />
    <p>{`We need to have two إمَّا in a single sentence for it to be treated as Harf Atf.`}<br parentName="p"></br>{`
`}{`For example in sentence, ادرس `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`إمَّا`}</strong>{` النحوَ و`}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`إمَّا`}</strong>{` الصرفَ  `}</p>
    <ul>
      <li parentName="ul">{`First إمَّا is not Harf Atf (No debate among Ulema)`}</li>
      <li parentName="ul">{`Secong إمَّا is harf Atf i.e. حرف عطف مبني على السكون (In this Ulema differs some say it is harf Atf some say it is not)`}</li>
      <li parentName="ul">{`الواو is حرف زائد لا محل له`}</li>
    </ul>
    <br />
 
Below chart lists all the عطف نسق
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "601px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "36.809815950920246%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABEklEQVQY03VO226CQBTk/z+nMenFGnsRQQRjvREs7QNo2+Be2N2zy0JY7OqLaWLnYXJyzsyccY5X0O1wG70zd7EPEpITYzfXZEfnqhlBF+cQxl+WC26O/+Bk7rpLsJ1rrTFCWZalaZrnOUIHpWTbtn/iz5bL59aYSuuSMUwI51wppbVumkYIUZ4BAHVdG3Mp4pimqQCkVWBMMQIhKhDqvNFKVgJskUoICcBPoYwTrKS0Amt0yjBA4VTGG7Ze0miqPj/QLGKrBVvM5WZFo1AlceGNq21irzrd/oxHapscAr+Mps6hf09GrzTwaTAhL098vdwNHunEp55LPBc/D2161ruBt3kx6Fve3/bEfFYMB98Pd7+ZkYZy5GINHQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Types of Atf Nask",
          "title": "Types of Atf Nask",
          "src": "/static/03af9d2151a6ee06791b6316f7b2b387/d8f62/atf-nask.png",
          "srcSet": ["/static/03af9d2151a6ee06791b6316f7b2b387/222b7/atf-nask.png 163w", "/static/03af9d2151a6ee06791b6316f7b2b387/ff46a/atf-nask.png 325w", "/static/03af9d2151a6ee06791b6316f7b2b387/d8f62/atf-nask.png 601w"],
          "sizes": "(max-width: 601px) 100vw, 601px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2><strong parentName="h2" {...{
        "className": "arabic"
      }}>{`التوكيد`}</strong></h2>
    <p>{`There are two types of التوكيد`}</p>
    <ul>
      <li parentName="ul">{`توكيد معنوي `}</li>
      <li parentName="ul">{`توكيد لفظي`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "401px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.306748466257666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABMUlEQVQoz42Q207CQBCG+/5voFcG8BAvSIMHBIslipRTKRciIKkhYi1cKBBpl93ZnToYQ6T0gslkszsz3/9nR4u2QynsjlnPV4MpJT57ojNi/kxQC6N4aLE3SDWcYrn1epjJHevmQVp33IW/UEnsDiwVvkyg4y7u2yPDGrSHnz0PJnOIkkLbLXGQK4GMI1thwGTIpUTcF177g0S1BpRUUkC0J4yIggMx/1e4qcREtmBqAShM8qE6ZdwZhaAkTdIOZ0sy+vUSW6IAKIEE2Gyp+HoYaQ9CaMta9btuvRULXT37UTJGhTydod2UYbghw2Z9Xq14pVvfLH1VHtz81bRsMruprRq1/mWucXpin5+93xmPmVT/IscdG4Lg789C8HZrbBStTPpJz9Klkjpyb67BsX8ABR42rQZTq2oAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Types of Tawkeed",
          "title": "Types of Tawkeed",
          "src": "/static/d3dd1f5f5758fcdc0ddc3f5c178a1065/9144d/tawkeed.png",
          "srcSet": ["/static/d3dd1f5f5758fcdc0ddc3f5c178a1065/222b7/tawkeed.png 163w", "/static/d3dd1f5f5758fcdc0ddc3f5c178a1065/ff46a/tawkeed.png 325w", "/static/d3dd1f5f5758fcdc0ddc3f5c178a1065/9144d/tawkeed.png 401w"],
          "sizes": "(max-width: 401px) 100vw, 401px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Let's begin by discussing ...`}</p>
    <h3><strong parentName="h3" {...{
        "className": "arabic"
      }}>{`توكيد معنوي`}</strong></h3>
    <p>{`In التوكيد المعنوي, we use specific words (ألفاظ خاصة) to make التوكيد. They are as follows,`}</p>
    <ul>
      <li parentName="ul">{`نفس`}</li>
      <li parentName="ul">{`عين`}</li>
      <li parentName="ul">{`كُلُّ`}</li>
      <li parentName="ul">{`كلا `}</li>
      <li parentName="ul">{`كلتا`}</li>
      <li parentName="ul">{`جميع `}</li>
      <li parentName="ul">{`عامة`}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`Use of نفس/عين`}</strong><br parentName="p"></br>{`
`}{`We use نفس OR عين to remove any ambiguity.`}<br parentName="p"></br>{`
`}{`For example in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`جاء الملك نفسُه`}</code>{` (King himself came)`}</p>
    <blockquote>
      <p parentName="blockquote">{`نفس
: توكيد مرفوع وعلامة رفعه الضمة`}</p>
    </blockquote>
    <br />
    <p>{`In above sentence, if we have just said `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`جاء الملكُ`}</code>{` then it `}<em parentName="p">{`MAY`}</em>{` mean that king has sent someone on his behalf but by using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`نفسُه`}</code>{` we have removed all the ambiguity.`}</p>
    <p>{`Similarly, we have`}</p>
    <ul>
      <li parentName="ul">{`جاء زيدٌ نفسُه`}</li>
      <li parentName="ul">{`رأيت زيداً نفسَه`}</li>
      <li parentName="ul">{`ذهبت إلى زيدٍ نفسِه`}</li>
    </ul>
    <p>{`In all above examples, زيد is مؤكد and نفس is توكيد`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`Use of كُلُّ`}</strong><br parentName="p"></br>{`
`}{`For things like a book (which have parts) we can use كلَّه, for example when I say  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`قرأت الكتابَ كلَّه`}</code><br parentName="p"></br>{`
`}{`I mean I've read all the book (not a single page is left)`}</p>
    <br />
    <p>{`Quranic Example: `}</p>
    <ul>
      <li parentName="ul">{`هَا أَنتُمْ أُولَاءِ تُحِبُّونَهُمْ وَلَا يُحِبُّونَكُمْ وَتُؤْمِنُونَ بِالْكِتَابِ `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`كُلِّ`}</strong>{`هِ`}</li>
      <li parentName="ul">{`وَلَقَدْ أَرَيْنَاهُ آيَاتِنَا `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`كُلَّ`}</strong>{`هَا فَكَذَّبَ وَأَبَىٰ `}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`Use of كلا OR كلتا`}</strong><br parentName="p"></br>{`
`}{`We use كلا OR كلتا for Dual.`}<br parentName="p"></br>{`
`}{`For example`}</p>
    <ul>
      <li parentName="ul">{`جاء الطالبان كلاهما (Masculine)`}</li>
      <li parentName="ul">{`جاءت الطالبتان كلتاهما (Feminine)`}</li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading2"
      }}>{`Use of جميع`}</strong><br parentName="p"></br>{`
`}{`We use جميع with plural.`}<br parentName="p"></br>{`
`}{`For example in جاء الطلابُ `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`جميعُ`}</strong>{`هم (here جميعُ is توكيد and هم goes back to الطلابُ).`}</p>
    <br />
    <p>{`We have a similar construction جاء الطلابُ `}<strong parentName="p">{`جميعاً`}</strong>{`, here جميعاً is حال منصوب وعلامة نصبه الفتحة not توكيد`}</p>
    <br />
    <p>{`Quranic Example:`}<br parentName="p"></br>{`
`}{`فسجد الملائكةُ `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`كلُّ`}</strong>{`هم `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`أجمعون`}</strong></p>
    <blockquote>
      <p parentName="blockquote">{`كل
: توكيد مرفوع وعلامة رفعه الضمة`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`أجمعون
: توكيد مرفوع وعلامة رفعه الواو`}</p>
    </blockquote>
    <h3><strong parentName="h3" {...{
        "className": "arabic"
      }}>{`توكيد اللفظي`}</strong></h3>
    <p>{`In التوكيد اللفظي, we have repetition of word (تكرار اللفظ) or meaning (معناه).`}<br parentName="p"></br>{`
`}{`For example,`}</p>
    <ul>
      <li parentName="ul">{`In `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`جاء جاء زيد`}</code>{` we have repetition of word جاء`}</li>
      <li parentName="ul">{`In `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`نعم نعم جاء زيد`}</code>{` we have repetition of word نعم`}</li>
      <li parentName="ul">{`In `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`الصلاةُ`}</strong>{` `}<strong parentName="li" {...{
          "className": "bg-cyan"
        }}>{`الصلاةُ`}</strong>{` عمودُ الدينِ we have repetition of word الصلاة`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`الصلاةُ`}</strong>{`: مبتدأ مرفوع`}</li>
          <li parentName="ul"><strong parentName="li" {...{
              "className": "bg-cyan"
            }}>{`الصلاةُ`}</strong>{`: توكيد لفظي مرفوع`}</li>
        </ul>
      </li>
      <li parentName="ul">{`كتب`}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`ت`}</strong>{` `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`أنت`}</strong>{` الدرس, here we have repeated meaning`}</li>
      <li parentName="ul">{`اسكن `}<strong parentName="li" {...{
          "className": "bg-yellow"
        }}>{`أنت`}</strong>{` وزوجك الجنة, here we have repeated meaning. Please note one أنت is hidden inside اسكن`}</li>
      <li parentName="ul">{`Quranic Example:`}
        <ul parentName="li">
          <li parentName="ul">{`وَالسَّابِقُونَ `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`السَّابِقُونَ`}</strong>{` أُولَٰئِكَ الْمُقَرَّبُونَ`}</li>
        </ul>
      </li>
    </ul>
    <h2><strong parentName="h2" {...{
        "className": "arabic"
      }}>{`البدل`}</strong></h2>
    <p>{`To understand البدل lets take an example,`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`جاء الخليفةُ عمرُ`}</code></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`الخليفة`}</strong>{`
(فاعل مرفوع (مبدل منه`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`عمر`}</strong>{`
بدل مرفوع`}</p>
    </blockquote>
    <p>{`here الخليفة and عمر represents same person i.e. الخليفة = عمر`}</p>
    <br />
    <p>{`البدل has following 4 kinds  `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`(بدل كل من كل (بدل مطابق`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Example: جاء الخليفةُ عمرُ here عمر is الخليفة Or in other words كل عمر is كل الخليفةُ`}</li>
          <li parentName="ul">{`Quranic Example:`}
            <ul parentName="li">
              <li parentName="ul">{`اهدنا `}<strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`الصراطَ`}</strong>{` المستقيم `}<strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`صراطَ`}</strong>{` الذين أنعمت عليهم here الصراطَ is بدل`}</li>
              <li parentName="ul">{`قَالُوا آمَنَّا بِ`}<strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`رَبِّ`}</strong>{` الْعَالَمِينَ `}<strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`رَبِّ`}</strong>{` مُوسَىٰ وَهَارُونَ`}</li>
              <li parentName="ul">{`وَقَالَ مُوسَىٰ لِأَخِيهِ `}<strong parentName="li" {...{
                  "className": "bg-yellow"
                }}>{`هَارُونَ`}</strong>{` اخْلُفْنِي فِي قَوْمِي وَأَصْلِحْ وَلَا تَتَّبِعْ سَبِيلَ الْمُفْسِدِينَ`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Also عطف بيان is equal to بدل كل من كل`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`بدل بعض من كل`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Example: أكلت `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`التفاحة`}</strong>{` `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`نصف`}</strong>{`ها (I ate the Apple half of it). Here البدل is part of مبدل منه`}</li>
          <li parentName="ul"><strong parentName="li">{`Rule 1:`}</strong>{`  البدل (بعض) أقل من المبدل منه`}</li>
          <li parentName="ul"><strong parentName="li">{`Rule 2:`}</strong>{` البدل دائما يكون مضافاً إلى ضمير يعود على المبدل منه`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`بدل الاشتمال`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`In this البدل is not part of مبدل منه but it is like its part`}</li>
          <li parentName="ul">{`Example: أحبُّ `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`الطالبَ`}</strong>{` `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`خُلُقَ`}</strong>{`ه (I love the student, his manners). In this خُلُقَ is not the student neither it is half of student (a material part of student) but a student has خُلُقَ. So its like its part not its material part.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`بدل غلط`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`In this we correct a mistake in the sentence`}</li>
          <li parentName="ul">{`Example: الكعبة في المدينةِ مكةَ. In this sentence we wanted to say Ka'ba is in Makka but we said Al Madina and then we corrected ourselves.`}</li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "601px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.74233128834356%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCUlEQVQY04VQXUvDMBTN//8BQ9HuA0FFQRCffN/ThqIybWe32ibTGnFOSpOsSe6tabMHC4KH5HLIveecS0jdAtv6Xdoo18/vJuZ2EvJpyB2Z52bJtQU/0gH5Ld448auOOURv1fX4aXzPFh8wz+0/Yg9AVBUIZYuyEhKkwlJqoUylof4LpMadJQJa465tTzPtubMEY62Lxm44IvE7GwNNd5fvW011Jp44M6c33e1JsUwkZQhg1p+Sppt4obIMRIlaV4xKRsvkZbtiesVgq0AIkSRFkkpKK5qRm/0evbpsfms6ud3rzY6G4TBQjKIo6dnJ42jwMAjugoP09Nh+rTXn0ag/c4/9Q3Zx/gMgAIoKQa0mjAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Types of Badl",
          "title": "Types of Badl",
          "src": "/static/de1a79b387f71e65f1f5552bdcacfeec/d8f62/badl.png",
          "srcSet": ["/static/de1a79b387f71e65f1f5552bdcacfeec/222b7/badl.png 163w", "/static/de1a79b387f71e65f1f5552bdcacfeec/ff46a/badl.png 325w", "/static/de1a79b387f71e65f1f5552bdcacfeec/d8f62/badl.png 601w"],
          "sizes": "(max-width: 601px) 100vw, 601px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Phew !!`}</p>
    <p>{`Take Deep Breath people. I know it's a lot but that how tawabi in Arabic works :)`}</p>
    <h2>{`Reference`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      